import { UsuarioCompartilhado } from './../model/usuariocompartilhado.model';
import { UserContribuinte } from './../model/usercontribuinte.model';
import { ConfigConst } from './../../core/util/config.const';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class UsuariocompartilhadoService {
  
  uriUser = ConfigConst.baseURL + 'usuarioscompartilhados/';
  uriCont = ConfigConst.baseURL + 'contusuariocompartilhado/';

  constructor( 
    private router: Router,
    private http: HttpClient,
    private messageService: MessageService
  ) { }

  criarUsuario(users: UserContribuinte): Observable<UserContribuinte[]> {
    return this.http.post<UserContribuinte[]>(this.uriUser + "criar", users);
  }

  atualizarUsuario(users: UserContribuinte): Observable<UserContribuinte[]> {
    return this.http.post<UserContribuinte[]>(this.uriUser + "atualizar", users);
  }

  buscarUsuario(email: string): Observable<any[]> {
    return this.http.post<any[]>(this.uriUser + "buscar",{'EmailLogin':email});
  }

  desabilitarUsuario(email: string, status: string): Observable<any[]> {
    return this.http.post<any[]>(this.uriUser + "desabilitar",{'EmailLogin':email, 'Status':status});
  }

  criarContribuinteUsuario(contribuinteusuario:any): Observable<any[]> {
    return this.http.post<any[]>(this.uriCont + "criar", contribuinteusuario);
  }

  atualizarContribuinteUsuario(contribuinteusuario:UsuarioCompartilhado): Observable<UsuarioCompartilhado[]> {
    return this.http.post<UsuarioCompartilhado[]>(this.uriCont + "atualizar", contribuinteusuario);
  }

  buscarContribuinteUsuario(email:string): Observable<UsuarioCompartilhado[]> {
    // console.log(this.uriCont + "buscar/"+email);
    return this.http.get<UsuarioCompartilhado[]>(this.uriCont + "buscar/"+email);
  }
}
